import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { HttpClient } from '@angular/common/http';

import { ToastrService } from 'ngx-toastr';
import { cloneDeep } from 'lodash-es';

import { UIProject } from '../../ematic-core-ui/models/ui-project';
import { AsyncApi } from '../../ematic-core-ui/api/async-api';
import {
  IEspData,
  IPartnerCategoryFilter,
  IApplicableMarket,
  PricingIndicator,
  IPartnerCategory,
  IPartnerStatusFilter,
  IAutomatedIntegrationEsp
} from '../../models/esp';
import { MailChimpService } from '../mailchimp/mailchimp.service';
import { CampaignMonitorService } from '../campaign-monitor/campaign-monitor.service';
import { PlatformService } from '../../ematic-core-ui/services/platform/platform.service';
import { constants } from '../../strings/constants';
import { environment } from '../../../environments/environment';

@Injectable()
export class StaticService extends AsyncApi {
  public esps: IEspData[];
  public partnerPriceRangeFilterValues: PricingIndicator[];
  public partnerCategoryFilterValues: IPartnerCategoryFilter[];
  public partnerStatusFilterValues: IPartnerStatusFilter[];

  constructor(
    router: Router,
    http: HttpClient,
    toastr: ToastrService,
    platformService: PlatformService,
    private mailChimpService: MailChimpService,
    private campaignMonitorService: CampaignMonitorService) {
    super(http, router, toastr, '/static', platformService, UIProject.PLATFORM_DASHBOARD);

    this.partnerPriceRangeFilterValues = ['$', '$$', '$$$', '$$$$'];

    this.partnerStatusFilterValues = [
      { name: 'All', value: 'all' },
      { name: 'Active', value: 'active' },
      { name: 'Inactive', value: 'inactive' }
    ];

    this.partnerCategoryFilterValues = [
      { id: 'ab-testing', text: 'A/B Testing' },
      { id: 'app-store-optimization', text: 'App Store Optimization' },
      { id: 'bot-platforms', text: 'Bot Platforms' },
      { id: 'capital-deployment', text: 'Capital Deployment' },
      { id: 'chatbots', text: 'Chatbots' },
      { id: 'cloud-communication-platforms', text: 'Cloud Communication Platforms' },
      { id: 'complain-management', text: 'Complain Management' },
      { id: 'consent-management-platform', text: 'Consent Management Platform (CMP)' },
      { id: 'contact-center-knowledge-base', text: 'Contact Center Knowledge Base' },
      { id: 'contact-center-operations', text: 'Contact Center Operations' },
      { id: 'content-analytics', text: 'Content Analytics' },
      { id: 'conversational-marketing', text: 'Conversational Marketing' },
      { id: 'customer-advocacy', text: 'Customer Advocacy' },
      { id: 'customer-data-platform', text: 'Customer Data Platform (CDP)' },
      { id: 'customer-journey-analytics', text: 'Customer Journey Analytics' },
      { id: 'customer-journey-mapping', text: 'Customer Journey Mapping' },
      { id: 'customer-relationship-management', text: 'Customer Relationship Management' },
      { id: 'customer-self-service', text: 'Customer Self-Service' },
      { id: 'data-governance', text: 'Data Governance' },
      { id: 'data-privacy-management', text: 'Data Privacy Management' },
      { id: 'data-subject-access-request', text: 'Data Subject Access Request (DSAR)' },
      { id: 'digital-analytics', text: 'Digital Analytics' },
      { id: 'digital-marketing-services', text: 'Digital Marketing Services' },
      { id: 'e-commerce-analytics', text: 'E-Commerce Analytics' },
      { id: 'e-commerce-data-integration', text: 'E-Commerce Data Integration' },
      { id: 'e-commerce-personalization', text: 'E-Commerce Personalization' },
      { id: 'e-commerce-platform', text: 'E-Commerce Platform' },
      { id: 'e-commerce-tools', text: 'E-Commerce Tools' },
      { id: 'email-deliverability', text: 'Email Deliverability' },
      { id: 'email-marketing', text: 'Email Marketing' },
      { id: 'email-template-builder', text: 'Email Template Builder' },
      { id: 'email-verification', text: 'Email Verification' },
      { id: 'fraud-detection', text: 'Fraud Detection' },
      { id: 'g-suite-communication-tools', text: 'G Suite Communication Tools' },
      { id: 'geofencing', text: 'Geofencing' },
      { id: 'helpdesk', text: 'Helpdesk' },
      { id: 'it-service-management-tools', text: 'IT Service Management Tools' },
      { id: 'lead-capture', text: 'Lead Capture' },
      { id: 'live-chat', text: 'Live Chat' },
      { id: 'loyalty-management', text: 'Loyalty Management' },
      { id: 'market-intelligence', text: 'Market Intelligence' },
      { id: 'marketing-analytics', text: 'Marketing Analytics' },
      { id: 'marketing-automation', text: 'Marketing Automation' },
      { id: 'marketing-funding', text: 'Marketing Funding' },
      { id: 'mobile-app-analytics-tools', text: 'Mobile App Analytics Tools' },
      { id: 'mobile-app-optimization', text: 'Mobile App Optimization' },
      { id: 'mobile-attribution', text: 'Mobile Attribution' },
      { id: 'mobile-marketing', text: 'Mobile Marketing' },
      { id: 'motion-ads', text: 'Motion Ads' },
      { id: 'online-community-management', text: 'Online Community Management' },
      { id: 'online-reputation-management', text: 'Online Reputation Management' },
      { id: 'personalization', text: 'Personalization' },
      { id: 'personalization-engines', text: 'Personalization Engines' },
      { id: 'pop-up-builder', text: 'Pop-Up Builder' },
      { id: 'proactive-notification', text: 'Proactive Notification' },
      { id: 'product-analytics', text: 'Product Analytics' },
      { id: 'product-recommendation', text: 'Product Recommendation' },
      { id: 'product-reviews', text: 'Product Reviews' },
      { id: 'push-notification', text: 'Push Notification' },
      { id: 'reporting-automation-tools', text: 'Reporting Automation Tools' },
      { id: 'retargeting', text: 'Retargeting' },
      { id: 'sap-app-center', text: 'SAP App Center' },
      { id: 'shipment-tracking-platform', text: 'Shipment Tracking Platform' },
      { id: 'sms-marketing', text: 'SMS Marketing' },
      { id: 'social-customer-service', text: 'Social Customer Service' },
      { id: 'social-media-advertising', text: 'Social Media Advertising' },
      { id: 'subscription-billing', text: 'Subscription Billing' },
      { id: 'transactional-email', text: 'Transactional Email' },
      { id: 'user-generated-content', text: 'User-Generated Content' },
      { id: 'warehouse-management', text: 'Warehouse Management' },
      { id: 'website-builder', text: 'Website Builder' },
      { id: 'website-personalization', text: 'Website Personalization' }
      // TODO: Uncomment when EmaticJS available
      // { id: 'web-optimization', text: 'Web optimization' }
    ];
  }

  getPartnersStatusFilterValues(): IPartnerStatusFilter[] {
    return this.partnerStatusFilterValues;
  }

  getPartnersPriceRangeFilterValues(): PricingIndicator[] {
    return this.partnerPriceRangeFilterValues;
  }

  getPartnersCategoryFilterValues(): IPartnerCategoryFilter[] {
    return this.partnerCategoryFilterValues;
  }

  async queryApplicableMarkets(): Promise<IApplicableMarket[]> {
    try {
      return await this.getAsync(null, '/applicable-markets');
    } catch (error) {
      this.toastr.error('There was an error while retrieving countries list.', 'Applicable Markets');
    }
  }

  async queryEspsByProductType(productType: string): Promise<IEspData[]> {
    return await this.getAsync({ productType }, '/filtered/by-product-type');
  }

  async getEspData(productType: string, espType: string): Promise<IEspData> {
    if (!this.esps) {
      this.esps = await this.queryEspsByProductType(productType);
    }
    const espData = this.esps.find(esp => esp.type === espType);
    return cloneDeep(espData);
  }

  getIntegrationEsps(isAutomatedIntegration: boolean): IAutomatedIntegrationEsp[] {
    let esps;

    if (isAutomatedIntegration) {
      esps = Object.keys(constants.automatedIntegrationEsps).map(
        key => Object.assign(constants.automatedIntegrationEsps[key]));
    } else {
      esps = Object.keys(constants.esps).map(
        key => Object.assign(constants.esps[key], { isActive: false }));
    }

    return esps.map(esp => this.getEspImagesByEnvironment(esp));
  }

  getEspImagesByEnvironment(esp: IAutomatedIntegrationEsp): IAutomatedIntegrationEsp {
    for (const prop in esp.logo) {
      if (!esp.logo[prop].startsWith('https://')) {
        esp.logo[prop] = environment.googleStorage.marketlaceUrl + esp.logo[prop];
      }
    }

    if (esp.images?.length) {
      esp.images = esp.images.map(image => {
        for (const prop in image) {
          if (!image[prop].startsWith('https://')) {
            image[prop] = environment.googleStorage.marketlaceUrl + image[prop];
          }
        }
        return image;
      });
    }

    return esp;
  }

  getOAuthCode(espType: string) {
    switch (espType) {
      case constants.esps.mailchimp.type:
        return this.mailChimpService.getOAuthCode();
      case constants.esps.campaignMonitor.type:
        return this.campaignMonitorService.getOAuthCode();
      default:
        return null;
    }
  }

  getCompleteOAuthParams(espType: string, code: string) {
    switch (espType) {
      case constants.esps.mailchimp.type:
        return this.mailChimpService.getCompleteOAuthParams(code);
      case constants.esps.campaignMonitor.type:
        return this.campaignMonitorService.getCompleteOAuthParams(code);
      default:
        throw new Error(constants.errors.esp.unsupported);
    }
  }

  getSubmitOAuthParams(espType: string, instanceId: string, update: boolean) {
    switch (espType) {
      case constants.esps.mailchimp.type:
        return this.mailChimpService.getSubmitOAuthParams(instanceId);
      case constants.esps.campaignMonitor.type:
        return this.campaignMonitorService.getSubmitOAuthParams(instanceId, update);
      default:
        throw new Error(constants.errors.esp.unsupported);
    }
  }

  getPartnerCategoriesDisplayText(categories: IPartnerCategory[]): string {
    return categories.map(category => category.name).join(', ');
  }

  isOAuthInProgressByEsp(espType: string): boolean {
    switch (espType) {
      case constants.esps.mailchimp.type:
        return this.mailChimpService.isOAuthInProgress();
      case constants.esps.campaignMonitor.type:
        return this.campaignMonitorService.isOAuthInProgress();
      default:
        return false;
    }
  }

  isOAuthInProgress(): boolean {
    return this.mailChimpService.isOAuthInProgress() || this.campaignMonitorService.isOAuthInProgress();
  }

  removeOAuthCode(espType: string): void {
    switch (espType) {
      case constants.esps.mailchimp.type:
        this.mailChimpService.removeOAuthCode();
        break;
      case constants.esps.campaignMonitor.type:
        this.campaignMonitorService.removeOAuthCode();
    }
  }

  connect(text: string): void {
    switch (text) {
      case constants.esps.mailchimp.text:
        this.mailChimpService.connect();
        break;
      case constants.esps.campaignMonitor.text:
        this.campaignMonitorService.connect();
        break;
      default:
        throw new Error(constants.errors.esp.unsupported);
    }
  }
}
