import { IEnvironment } from '../app/models/environment';

export const environment: IEnvironment = {
  production: false,
  baseUrl: {
    amphoria: 'https://platform-api-staging.amphoria.io/api/dashboard',
    elixusagency: 'https://platform-api-staging.elixusagency.com/api/dashboard',
    ematicsolutions: 'https://platform-api-staging.ematicsolutions.com/api/dashboard',
    hiiq: 'https://platform-api-staging.hiiq.ai/api/dashboard'
  },
  stripePublishableKey: 'pk_test_KzBct0KUlKc5t7HeUgctuwRs',
  googleClientId: '989469996515-n17jihuskll7a1rkkodlq1f6rg1uujf4.apps.googleusercontent.com',
  reCaptchaSiteKey: '6Lf2U1AUAAAAAF9CxILLWocUDY0hmk3p9jNaEqdp',
  reCaptchaEnabled: false,

  redirectURI: 'https://platform-staging.ematicsolutions.com/oauth.html',
  mailchimp: {
    authorizeURI: 'https://login.mailchimp.com/oauth2/authorize',
    clientId: '381233245413'
  },
  campaignMonitor: {
    authorizeURI: 'https://api.createsend.com/oauth',
    clientId: '119641',
    loginURL: 'https://ematicsolutions12.createsend.com/login'
  },
  getResponse: {
    authorizeURI: 'https://app.getresponse.com/oauth2_authorize.html',
    clientId: 'e477399c-f962-11e9-a02a-00219ba6d669'
  },
  facebook: {
    authorizeURI: 'https://www.facebook.com/v9.0/dialog/oauth',
    clientId: '450219266031581'
  },

  intercom: {
    appId: 'rhrdllc1'
  },
  adminTool: {
    baseUrl: 'https://platform-admin-staging.ematicsolutions.com'
  },
  useHash: true,
  devEnvironment: false,
  googleAnalyticsId: 'UA-145772696-4',
  googleOptimizeId: 'GTM-NPHM9VC',
  googleTagManagerId: 'GTM-T8CZ6TB',
  ematicAnalytics: {
    gracePeriod: 7
  },
  emails: {
    amphoria: {
      supportTeam: 'support_test@ematicsolutions.com',
      inboundAccounts: 'inbound_accounts_test@ematicsolutions.com'
    },
    elixusagency: {
      supportTeam: 'support_test@ematicsolutions.com',
      inboundAccounts: 'inbound_accounts_test@ematicsolutions.com'
    },
    ematicsolutions: {
      supportTeam: 'support_test@ematicsolutions.com',
      inboundAccounts: 'inbound_accounts_test@ematicsolutions.com'
    },
    hiiq: {
      supportTeam: 'support_test@ematicsolutions.com',
      inboundAccounts: 'inbound_accounts_test@ematicsolutions.com'
    }
  },
  googleStorage: {
    marketlaceUrl: 'https://storage.googleapis.com/ematic-marketplace-staging-public'
  }
};
